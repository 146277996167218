function onItemByVolume (config, price, typePosition, globalPosition) {
    let oDiscount = { id: null, has: false, discount: 0, type: 'onItemByVolume', ranges: [] };
    // Se calcula segun la posicion
    let x = 0;
    for(x=0; x < config.params.ranges.length; x++) {
        if (config.params.ranges[x].quantityMin <= typePosition && config.params.ranges[x].quantityMax >= typePosition) {
            oDiscount.id = x;
            oDiscount.position = typePosition;
            oDiscount.discount = price * config.params.ranges[x].discount / 100;
            oDiscount.has = true;
            break;
        }
    }
    
    // Siguientes rangos!
    if (oDiscount.has) {
        // Actual
        oDiscount.ranges.push({
            price: price - oDiscount.discount,
            min: config.params.ranges[oDiscount.id].quantityMin,
            max: config.params.ranges[oDiscount.id].quantityMax
            });
        
        // if Next ...
        if (config.params.ranges.length-1 > oDiscount.x) {
            oDiscount.ranges.push({
                price: price - (price * config.params.ranges[oDiscount.id+1].discount / 100),
                min: config.params.ranges[oDiscount.id+1].quantityMin,
                max: config.params.ranges[oDiscount.id+1].quantityMax
                });
        }
        // if Next ...
        if (config.params.ranges.length-2 > oDiscount.x) {
            oDiscount.ranges.push({
                price: price - (price * config.params.ranges[oDiscount.id+2].discount / 100),
                min: config.params.ranges[oDiscount.id+2].quantityMin,
                max: config.params.ranges[oDiscount.id+2].quantityMax
                });
        }
    }
    
    return oDiscount;
}


export { onItemByVolume };