import React, { Component } from 'react';
import { withRouter } from "react-router";
import jQuery from 'jquery';
import {showLoading, hideLoading} from "../../util/SwitchLoading";
import ConfigSite from "../../ConfigSite";
import SimpleGalleryContainerLink from "../../ui/fanatico/poster/SimpleGalleryContainerLink";
import SimpleGalleryContainerClick from "../../ui/fanatico/poster/SimpleGalleryContainerClick";
const u = jQuery.noConflict();

const config = new ConfigSite();
const API_POSTER = 'es/cinematheque/poster/';
const API_SUMMARY = 'es/cinematheque/summary/';

const gotoTop = () => {
    window.scrollTo(0, 0);
}

const hideAll = () => {
    u('#top').hide();
    u('#editorSection').hide();
    u('#authorContainer').hide();
    u('#cartSection').hide();
    u('#not-found').hide();
    u('#favoritesInstructions').hide();
    u('#btnAddToCartContainer').hide();
}

class PosterPage extends Component {

    constructor(props) {
        super(props);
        showLoading();

        u('body').addClass('producto');
        u('body').removeClass('home');

        hideAll();
        gotoTop();

        this.state = {
            poster: null,
            isLoading: true,
        };
    }

    componentDidMount() {
        if (this.props.origin === "poster" ) {
            this.getPageDataFromPoster();
        } else {
            this.getPageDataFromMovie();
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.origin === "poster" ) {
            if (prevProps.match.params.id_image !== this.props.match.params.id_image) {
                this.getPageDataFromPoster();
            }
        } else {
            if (prevProps.match.params.id_media !== this.props.match.params.id_media ||
                prevProps.match.params.media_type !== this.props.match.params.media_type) {
                this.getPageDataFromMovie();
            }
        }
    }

    getPageDataFromPoster() {
        showLoading();
        hideAll();

        this.setState({
            poster: null,
            isLoading: true
        });

        let id_image = this.props.match.params.id_image;

        fetch(config.global.mediaApi.urlBase + API_POSTER + id_image)
            .then(response => response.json())
            .then(function(data) {
                // Si tengo imagen seteo la primera :)
                if (data.selected && data.selected.data) {
                    const dataImage = {...data.selected.data};
                    window.Editor.setImage(dataImage);
                    gotoTop();
                }

                this.setState({
                    poster: data,
                    isLoading: false
                });
            }.bind(this));
    }

    getPageDataFromMovie() {
        showLoading();
        hideAll();

        this.setState({
            poster: null,
            isLoading: true
        });

        let media_type = this.props.match.params.media_type;
        let id_media = this.props.match.params.id_media;

        fetch(config.global.mediaApi.urlBase + API_SUMMARY + 'portrait/' + media_type + '/' + id_media)
            .then(response => response.json())
            .then(function(data) {
                // Si tengo imagen seteo la primera :)
                if (data.selected && data.selected.data) {
                    const dataImage = {...data.images[0].data};
                    window.Editor.setImage(dataImage);
                    gotoTop();
                }

                this.setState({
                    poster: data,
                    isLoading: false
                });

            }.bind(this));


    }


    render() {
        const { poster, isLoading } = this.state;

        if (isLoading) {
            return ('');
        }

        let title = "";
        if (poster.images.length > 0) {
            title = "Más de " + (poster.selected.data.metadata ? poster.selected.data.metadata.name : " ... ");
            u('#linkAuthor').html(poster.selected.data.publisher.name);
            u('#editorSection').show();
            u('#authorContainer').show();
            u('#cartSection').show();

        } else {
            u('.imgMovieNotFound').attr('src', 'https://image.tmdb.org/t/p/w220_and_h330_face' + poster.film.poster_path );
            u('.linkMovieNotFound').attr('href','https://www.themoviedb.org/' + (poster.film.title ? 'movie' : 'tv') + '/' + poster.film.id + '/images/posters' );
            u('.titleMovieNotFound').html(poster.film.title ? poster.film.title : poster.film.name);
            u('#not-found').show();
            u('#sectionComoFunciona').hide();
        }

        gotoTop();
        hideLoading();

        return (
            <React.Fragment>

                {
                    poster.images.length > 1 ? (
                        <section className={"template_align_right"} >
                            <SimpleGalleryContainerClick images={poster.images} name={title} slug={"posters"}/>
                        </section>
                    ) : ""
                }
                {
                    poster.images.length > 0 ? (
                        <section className={"como-funciona como-funciona-desktop"} dangerouslySetInnerHTML={{__html: u('#sectionComoFunciona').html()}} />
                    ) : ""
                }

                <section className={"template_align_right"} >
                    <SimpleGalleryContainerLink images={poster.relateds} name={"Relacionados"} slug={"related"}/>
                </section>
            </React.Fragment>
        );

    }
}

export default withRouter(PosterPage);