import jQuery from 'jquery';
const u = jQuery.noConflict();

export function showLoading() {
    u('.posterInfoLoading').show();
}

export function hideLoading() {
    u('.posterInfoLoading').hide();
}

