import React from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";
import PosterPage from "./poster/PosterPage";
import HomePage from "./poster/HomePage";
import ScrollToTop from "../ui/common/ScrollToTop";
import CollectionPage from "./poster/CollectionPage";
import CustomPage from "./poster/CustomPage";
import FavoritesPage from "./poster/FavoritesPage";

export default class PosterRouter extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
        <Router>
            <ScrollToTop />
            <Switch>
                <Route path="/p/poster/favorites">
                    <FavoritesPage />
                </Route>
                <Route path="/p/poster/view/poster/:id_image">
                    <PosterPage origin={"poster"} />
                </Route>
                <Route path="/p/poster/show/:media_type/:id_media">
                    <PosterPage origin={"movie"} />
                </Route>
                <Route path="/p/poster/collection/:name">
                    <CollectionPage />
                </Route>
                <Route path="/p/poster/custom/:layout">
                    <CustomPage />
                </Route>
                <Route path="/p/poster/custom">
                    <CustomPage />
                </Route>
                <Route path="/p/poster">
                    <HomePage />
                </Route>
                <Route path="/">
                    <HomePage />
                </Route>
            </Switch>
        </Router>
        );
    }
}