import React, { Component } from "react";
import { withRouter } from "react-router";
import {showLoading, hideLoading} from "../../util/SwitchLoading";
import jQuery from 'jquery';
import ConfigSite from "../../ConfigSite";
import SimpleGalleryContainerLink from "../../ui/fanatico/poster/SimpleGalleryContainerLink";
const u = jQuery.noConflict();

const config = new ConfigSite();
const API = 'es/cinematheque/collection/';

const gotoTop = () => {
    window.scrollTo(0, 0);
}

const hideAll = () => {
    u('#top').hide();
    u('#editorSection').hide();
    u('#authorContainer').hide();
    u('#cartSection').hide();
    u('#not-found').hide();
    u('#favoritesInstructions').hide();
    u('#btnAddToCartContainer').hide();
}

class FavoritesPage extends Component {

    constructor(props) {
        super(props);

        showLoading();
        hideAll();
        u('body').addClass('home');
        u('body').removeClass('producto');
        u('section.top').show();
        u('#info').show();

        this.state = {
                favorites: [
                ],
                isLoading: true,
            };
    }

    componentDidMount() {
        showLoading();
        console.log(window.FUSIONN.favorites);
        this.setState({ isLoading: false, favorites: window.FUSIONN.listFavorites(null, null) });
    }

    render() {

        const { favorites, isLoading } = this.state;
        if (isLoading) {
            return ( "" );
        }

        gotoTop();
        hideLoading();

        if (favorites.length === 0) {
            u('#info').hide();
            u('#favoritesInstructions').show();
            u('#favoritesInstructions').get(0).scrollIntoView();
            return ("");
        }

        return (
            <section className={"template_align_right"} >
                <SimpleGalleryContainerLink images={favorites} name={'Mis favoritos'} slug={'favorites'}/>
            </section>
        );
    }
}

export default withRouter(FavoritesPage);