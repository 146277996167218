import React from "react";
import PosterRouter from "./PosterRouter";

export default function SiteRouter() {

    // Debe cambiar según el sitio al que estamos accediendo

    return (
        <PosterRouter />
    );
}
