import React, { Component } from 'react';
import {showLoading, hideLoading} from "../../util/SwitchLoading";
import ConfigSite from "../../ConfigSite";
import SimpleGalleryContainers from "../../ui/fanatico/home/SimpleGalleryContainers";

const config = new ConfigSite();
const API = 'es/cinematheque/home';

const gotoTop = () => {
    window.scrollTo(0, 0);
}

export default class HomeSection extends Component {

    constructor(props) {
        super(props);

        showLoading();

        this.state = {
            home: [
            ],
            isLoading: true,
        };
    }

    componentDidMount() {
        showLoading();
        this.setState({ isLoading: true });
        fetch(config.global.mediaApi.urlBase + API)
            .then(response => response.json())
            .then(data => {
                return this.setState({ home: data , isLoading: false });
            });
    }

    render() {
        const { home, isLoading } = this.state;
        if (isLoading) {
            return ( "" );
        }
        gotoTop();
        hideLoading();

        return (
            <SimpleGalleryContainers sliders={home} />
        );
    }
}