import ConfigSite from "../ConfigSite";

export default class ImageEvent {
    config;
    api;

    constructor() {
        this.config = new ConfigSite();
        this.api = this.config.global.mediaApi.urlBase;
    }

    clickEvent = (imageId, page, type) => {

        let dataToSend = {
            imageId: imageId,
            page: page,
            domain: window.location.hostname,
            url: window.location.pathname,
            type: type,
            search: window.lastTextSearch,
            id_sale: window.FUSIONN.session,
        }

        console.log(dataToSend);
        const api_url = this.api + "images/event";
        let request = async function(dataToSend, api_url) {
            await fetch(api_url, {
                method: 'POST',
                body: JSON.stringify(dataToSend),
                headers:{
                    'Content-Type': 'application/json'
                }
            });
        };
        request(dataToSend, api_url);
    };

}
