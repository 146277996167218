import React, { Component } from 'react';
import { withRouter } from "react-router";
import jQuery from 'jquery';
import {showLoading, hideLoading} from "../../util/SwitchLoading";
import ConfigSite from "../../ConfigSite";
const u = jQuery.noConflict();

const config = new ConfigSite();
const API_POSTER = 'es/cinematheque/poster/';
const API_SUMMARY = 'es/cinematheque/summary/';

const gotoTop = () => {
    window.scrollTo(0, 0);
}

const hideAll = () => {
    u('#top').hide();
    u('#editorSection').hide();
    u('#authorContainer').hide();
    u('#cartSection').hide();
    u('#not-found').hide();
    u('#favoritesInstructions').hide();
    u('#btnAddToCartContainer').hide();
}

class CustomPage extends Component {

    constructor(props) {
        super(props);
        showLoading();

        u('body').addClass('producto');
        u('body').removeClass('home');

        hideAll();
        gotoTop();

        this.state = {
            isLoading: false,
        };
    }

    render() {
        const { isLoading } = this.state;

        if (isLoading) {
            return ('');
        }
        window.Editor.setEditorType('fotoc', this.props.match.params.layout);
        u('#editorSection').show();
        u('#cartSection').show();

        gotoTop();
        hideLoading();

        return (
            <React.Fragment>
            </React.Fragment>
        );

    }
}

export default withRouter(CustomPage);