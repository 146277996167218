import React, { Component } from 'react';
import SimpleGalleryContainer from "./SimpleGalleryContainer";

const gotoTop = () => {
    window.scrollTo(0, 0);
}

export default class ImageCategoryFlickities extends Component {

    constructor(props) {
        super(props);
    }

    componentDidMount(){

    }

    render() {
        const {sliders} = this.props;
        return  (
            <React.Fragment>
            {
                sliders.length ? sliders.map((slider) => (<SimpleGalleryContainer {...slider} />)) : ""
            }
            </React.Fragment>
        );
    }
}
