import React, { Component } from "react";
import { withRouter } from "react-router";
import {showLoading, hideLoading} from "../../util/SwitchLoading";
import jQuery from 'jquery';
import ConfigSite from "../../ConfigSite";
import SimpleGalleryContainerLink from "../../ui/fanatico/poster/SimpleGalleryContainerLink";
const u = jQuery.noConflict();

const config = new ConfigSite();
const API = 'es/cinematheque/collection/';

const gotoTop = () => {
    window.scrollTo(0, 0);
}

const hideAll = () => {
    u('#top').hide();
    u('#editorSection').hide();
    u('#authorContainer').hide();
    u('#cartSection').hide();
    u('#not-found').hide();
    u('#favoritesInstructions').hide();
    u('#btnAddToCartContainer').hide();
}

class CollectionPage extends Component {

    constructor(props) {
        super(props);

        showLoading();
        hideAll();
        u('body').addClass('home');
        u('body').removeClass('producto');
        u('section.top').show();
        u('#info').show();

        this.state = {
                data: [
                ],
                isLoading: true,
            };
    }

    componentDidMount() {
        showLoading();
        this.setState({ isLoading: true });
        fetch(config.global.mediaApi.urlBase + API + this.props.match.params.name)
            .then(response => response.json())
            .then(data => {
                return this.setState({ data: data , isLoading: false });
            });
    }

    render() {

        const { data, isLoading } = this.state;
        if (isLoading) {
            return ( "" );
        }

        gotoTop();
        hideLoading();

        return (
            <section className={"template_align_right"} >
                <SimpleGalleryContainerLink images={data.images} name={data.name} slug={data.slug}/>
            </section>
        );
    }
}

export default withRouter(CollectionPage);